



























import Vue from 'vue'
import dialogTypes from '@/components/dialogs/dialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
export default Vue.extend({
  name: 'AppFooterAccount',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dialogTypes,
      dialogSize,
      isUserLogged: false
    }
  },
  computed: {
    menuList (): Record<string, any> {
      return [
        {
          name: this.data.configuration.wb_account_title__text__,
          key: 'footer-menu3',
          items: [
            {
              title: this.$trans('wb_login'),
              show: !this.isUserLogged,
              key: 'footer-log_in'
            },
            {
              title: this.$trans('wb_signup'),
              show: !this.isUserLogged,
              key: 'footer-register'
            },
            {
              title: this.$trans('wb_profile'),
              path: { name: 'account' },
              show: this.isUserLogged,
              key: 'footer-account'
            },
            {
              title: this.$trans('wb_settings'),
              path: { name: 'settings' },
              show: this.isUserLogged,
              key: 'footer-settings'
            }
          ]
        }
      ]
    }
  }
})
